import { Stores } from "core/bootstrap";
import { queryForTarget, queryForTemplate } from "core/content";
import { elementFromTemplate } from "core/template";
import Cookies from "js-cookie";
import { asPhone } from "lib/helpers";
import { GoogleSearchForm } from "modules/google-search";
import { LocationList } from "modules/location-list";
import { Studio } from "services/booking";
import { createStore } from "zustand/vanilla";

import { ANON_GUID_COOKIE_KEY } from "../lib/constants.ts";

const BOOKING_URL = `https://booking.restore.com/booking/book`;

function getAnonQuery(): Record<string, string> {
  const cookie = Cookies.get(ANON_GUID_COOKIE_KEY);
  if (cookie) {
    return {
      anon_id: cookie,
    };
  }
  return {};
}

function getBookingUrl(store: string, service?: string | null) {
  const anonQuery = getAnonQuery();

  const urlSearchParams = new URLSearchParams({
    store,
    ...anonQuery,
  });

  if (service) {
    urlSearchParams.set("service", service);
  }

  return `${BOOKING_URL}?${urlSearchParams.toString()}`;
}

export function renderStudioTemplate(template: HTMLElement, studio: Studio) {
  const { description, address, address2 = "", city, state, zip } = studio;

  const element = elementFromTemplate(template, {
    description,
    address,
    address2,
    city,
    state,
    zip,
    phone: studio.phone && asPhone(studio.phone),
    distance: String(Math.ceil(studio.distance)),
  });

  element.setAttribute("href", getBookingUrl(studio.storeCode));

  return element;
}

type Coordinates = {
  latitude: number;
  longitude: number;
  locationName?: string;
};

type CoordinateStore = {
  coordinates?: Coordinates;
  setCoordinates: (coordinates: Coordinates) => void;
};

export default function Booking(root: HTMLElement, { studioStore }: Stores) {
  const coordinateStore = createStore<CoordinateStore>((set) => ({
    setCoordinates: (coordinates) => set({ coordinates }),
  }));
  const storeCode = new URLSearchParams(window.location.search).get("location");
  const service = new URLSearchParams(window.location.search).get("service");

  if (storeCode) {
    window.location.href = getBookingUrl(storeCode, service);
    return;
  }

  const chooseLocationLink = queryForTarget<HTMLDivElement>(
    root,
    "choose-a-location",
  );

  const modalElement = queryForTarget<HTMLDivElement>(
    root,
    "choose-location-modal",
  );

  if (!modalElement || !chooseLocationLink) {
    return;
  }

  modalElement?.classList.add("open");

  chooseLocationLink.addEventListener("click", () => {
    modalElement?.classList.add("open");
  });

  const listElement = queryForTarget<HTMLElement>(root, "location-results");
  const formElement = root.querySelector<HTMLFormElement>("form");
  const locationTemplate = queryForTemplate<HTMLElement>(
    root,
    "location-result",
  );

  if (!formElement || !listElement || !locationTemplate) {
    return;
  }

  const list = new LocationList(listElement, {
    renderStudio: (studio) => renderStudioTemplate(locationTemplate, studio),
  });

  new GoogleSearchForm(formElement, (place, locationName) => {
    if (!place?.geometry?.viewport || !place.geometry.location) {
      return;
    }
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    coordinateStore.getState().setCoordinates({
      latitude,
      longitude,
      locationName,
    });
  });

  coordinateStore.subscribe((state) =>
    list.renderStudios(
      studioStore.getState().studios.filter((s) => s.isLive),
      state.coordinates,
    ),
  );
}
